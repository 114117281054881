import React, { useState, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [typedWord, setTypedWord] = useState('');
  const [typedSentence, setTypedSentence] = useState('');
  const word = '$ PEW';
  const sentence = ' The first communal memecoin on BLAST.';
  const sentenceDelay = 600;

  useEffect(() => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      if (currentIndex < word.length-1) {
        setTypedWord(prevTypedWord => prevTypedWord + word[currentIndex]);
        currentIndex++;
      } else {
        clearInterval(interval);
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      let currentIndex = 0;
      const interval = setInterval(() => {
        if (currentIndex < sentence.length-1) {
          setTypedSentence(prevTypedSentence => prevTypedSentence + sentence[currentIndex]);
          currentIndex++;
        } else {
          clearInterval(interval);
        }
      }, 35);

      return () => {
        clearInterval(interval);
      };
    }, sentenceDelay);

    return () => {
      clearTimeout(timeout);
    };
  }, [sentenceDelay, sentence]);


  return (
    <div className="container d-flex flex-column justify-content-center align-items-center vh-100">
      <h1 className="ibm-plex-mono-bold display-1 word">
        {typedWord}
      </h1>
      <p className="ibm-plex-mono-bold word">
          {typedSentence}
          {typedSentence && <span className="blinking-cursor">|</span>}
      </p>
      <span className="laser-beam"></span>
      <span className="laser-beam2"></span>
    </div>
  );
}
//
export default App;